const sketch8 = (paused) => (p) => {
  let circles = [];
  let orangeIndices = [];

  p.setup = () => {
    p.createCanvas(p.windowWidth, p.windowHeight);
    p.background(0);

    // Initialize circles with x positions and sine-based y positions
    for (let i = 0; i < p.width; i += 20) {
      circles.push({
        x: i,
        y: p.height / 2 + 50 * p.sin(i / 20),
        size: 50
      });
    }

    // Randomly select indices for bright orange outline
    for (let i = 0; i < 10; i++) { // Change the number to control how many circles are outlined in orange
      orangeIndices.push(Math.floor(p.random(circles.length)));
    }
  };

  p.draw = () => {
    if (paused) {
      p.noLoop();
    } else {
      p.loop();
    }

    p.background(0, 20); // Creates a trailing effect

    for (let i = 0; i < circles.length; i++) {
      let circle = circles[i];

      // Update y position based on sine wave
      circle.y = p.height / 2 + 50 * p.sin((p.frameCount + circle.x) / 20);

      if (orangeIndices.includes(i)) {
        p.stroke(255, 140, 0); // Bright orange color
      } else {
        p.stroke(255);
      }

      p.strokeWeight(2);
      p.noFill();
      p.ellipse(circle.x, circle.y, circle.size, circle.size);
    }
  };

  p.windowResized = () => {
    p.resizeCanvas(p.windowWidth, p.windowHeight);
    // Reinitialize circles on window resize
    circles = [];
    for (let i = 0; i < p.width; i += 20) {
      circles.push({
        x: i,
        y: p.height / 2 + 50 * p.sin(i / 20),
        size: 50
      });
    }
    orangeIndices = [];
    for (let i = 0; i < 10; i++) { // Change the number to control how many circles are outlined in orange
      orangeIndices.push(Math.floor(p.random(circles.length)));
    }
  };
};

export default sketch8;
