const sketch3 = (paused) => (p) => {
    let lines = [];
  
    p.setup = () => {
      p.createCanvas(p.windowWidth, p.windowHeight);
      p.background(18, 18, 18);
  
      for (let i = 0; i < 111; i++) {
        let lineObj = {
          x: p.random(p.width),
          y: p.random(-p.height, p.height),
          length: p.random(10, 50),
          speed: p.random(1, 5),
        };
        lines.push(lineObj);
      }
    };
  
    p.draw = () => {
      if (paused) {
        p.noLoop();
      } else {
        p.loop();
      }
  
      p.background(18, 18, 18, 50); // Creates a fading effect
  
      p.stroke(240);
      p.strokeWeight(2);
  
      for (let lineObj of lines) {
        p.line(lineObj.x, lineObj.y, lineObj.x, lineObj.y + lineObj.length);
        lineObj.y += lineObj.speed;
        if (lineObj.y > p.height) {
          lineObj.y = p.random(-p.height, 0);
        }
      }
    };
  
    p.windowResized = () => {
      p.resizeCanvas(p.windowWidth, p.windowHeight);
    };
  };
  
  export default sketch3;
  