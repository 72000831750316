import React from 'react';
import './App.css';
import AnimationFinal from './components/AnimationFinal';

function App() {
  return (
      <div>
      <audio id="background-music" src="http://uk2.internet-radio.com:8024/;" autoPlay loop />
      <AnimationFinal />
      </div>
  );
}

export default App;
