const sketch4 = (paused) => (p) => {
  let stars = [];
  let movingStars = [];

  p.setup = () => {
    p.createCanvas(p.windowWidth, p.windowHeight);
    for (let i = 0; i < 200; i++) {
      stars.push({
        x: p.random(p.width),
        y: p.random(p.height),
        size: p.random(1, 3),
        isMoving: false,
        vx: 0,
        vy: 0,
      });
    }

    // Randomly select some stars to be moving stars
    for (let i = 0; i < 20; i++) { // Change this number to control how many stars move
      const index = Math.floor(p.random(stars.length));
      stars[index].isMoving = true;
      stars[index].vx = p.random(-0.5, 0.5); // Very slow speed
      stars[index].vy = p.random(-0.5, 0.5); // Very slow speed
      movingStars.push(stars[index]);
    }
  };

  p.draw = () => {
    if (paused) {
      p.noLoop();
    } else {
      p.loop();
    }

    p.background(0);
    p.noStroke();

    for (let star of stars) {
      if (star.isMoving) {
        p.fill(255, 140, 0); // Bright orange color
        star.x += star.vx;
        star.y += star.vy;

        // Wrap around the edges
        if (star.x > p.width) star.x = 0;
        if (star.x < 0) star.x = p.width;
        if (star.y > p.height) star.y = 0;
        if (star.y < 0) star.y = p.height;
      } else {
        p.fill(255);
      }
      p.ellipse(star.x, star.y, star.size);
    }
  };

  p.windowResized = () => {
    p.resizeCanvas(p.windowWidth, p.windowHeight);
    // Reinitialize stars on window resize
    stars = [];
    movingStars = [];
    for (let i = 0; i < 200; i++) {
      stars.push({
        x: p.random(p.width),
        y: p.random(p.height),
        size: p.random(1, 3),
        isMoving: false,
        vx: 0,
        vy: 0,
      });
    }
    for (let i = 0; i < 20; i++) {
      const index = Math.floor(p.random(stars.length));
      stars[index].isMoving = true;
      stars[index].vx = p.random(-0.5, 0.5); // Very slow speed
      stars[index].vy = p.random(-0.5, 0.5); // Very slow speed
      movingStars.push(stars[index]);
    }
  };
};

export default sketch4;
