const sketch5 = (paused) => (p) => {
    p.setup = () => {
      p.createCanvas(p.windowWidth, p.windowHeight);
      p.background(0);
    };
  
    p.draw = () => {
      if (paused) {
        p.noLoop();
      } else {
        p.loop();
      }
  
      p.background(0, 10);
      p.stroke(255);
      p.strokeWeight(2);
      let t = p.frameCount / 60;
      for (let i = 0; i < p.width; i += 20) {
        let y = p.height / 2 + p.sin(t + i / 20) * 50;
        p.line(i, y, i, y + 20);
      }
    };
  
    p.windowResized = () => {
      p.resizeCanvas(p.windowWidth, p.windowHeight);
    };
  };
  
  export default sketch5;
  