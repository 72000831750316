import React, { useRef, useEffect, useState } from 'react';
import p5 from 'p5';
import sketch1 from './animations/sketch1';
import sketch2 from './animations/sketch2';
import sketch3 from './animations/sketch3';
import sketch4 from './animations/sketch4';
import sketch5 from './animations/sketch5';
import sketch6 from './animations/sketch6';
import sketch7 from './animations/sketch7';
import sketch8 from './animations/sketch8';

const AnimationFinal = () => {
  const animationRef = useRef();
  const [animationIndex, setAnimationIndex] = useState(0);
  const [opacity, setOpacity] = useState(1);
  const [paused, setPaused] = useState(false);

  const animations = [
    sketch1,
    sketch2,
    sketch3,
    sketch4,
    sketch5,
    sketch6,
    sketch7,
    sketch8
  ];

  useEffect(() => {
    const width = window.innerWidth / animations.length;
    const sketch = animations[animationIndex](paused, animationIndex * width, width);
    const p5Instance = new p5(sketch, animationRef.current);

    return () => {
      p5Instance.remove();
    };
  }, [animationIndex, paused]);

  useEffect(() => {
    const handleMouseMove = (event) => {
      if (paused) return;

      const width = window.innerWidth / animations.length;
      const newIndex = Math.floor(event.clientX / width);

      if (newIndex !== animationIndex) {
        setOpacity(0);
        setTimeout(() => {
          setAnimationIndex(newIndex);
          setOpacity(1);
        }, 500);
      }
    };

    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, [animationIndex, animations.length, paused]);

  const togglePause = () => {
    setPaused(!paused);
  };

  return (
    <div>
      <div
        ref={animationRef}
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          transition: 'opacity 0.5s',
          opacity: opacity,
          display: paused ? 'none' : 'block'
        }}
      ></div>
      {paused && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
            color: '#fff',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 10
          }}
        >
          <header>
              <h1>PRAWDZIWE•PIENIADZE</h1>
              <p>Odkryj magie run, zdobądź lepszy świat.</p>
            </header>
          <a
            href="https://magiceden.io/runes/PRAWDZIWE%E2%80%A2PIENIADZE"
            target="_blank"
            rel="noopener noreferrer"
            style={linkStyle}
          >
            🪄🪄🪄
          </a>
          <a
            href="https://thepolki.xyz"
            target="_blank"
            rel="noopener noreferrer"
            style={linkStyle}
          >
            🍑🍑🍑
          </a>
          <button onClick={togglePause} style={buttonStyle}>
            🤫
          </button>
        </div>
      )}
      <button
        onClick={togglePause}
        style={{
          position: 'fixed',
          bottom: '20px',
          left: '20px',
          padding: '10px 20px',
          backgroundColor: '#333',
          color: '#f0f0f0',
          border: 'none',
          fontSize: '1em',
          cursor: 'pointer',
          transition: 'background-color 0.3s'
        }}
      >
        {paused ? "⏸" : "ᛜ"}
      </button>
    </div>
  );
};

const linkStyle = {
  color: '#fff',
  fontSize: '1.2em',
  margin: '10px 0',
  textDecoration: 'none',
  backgroundColor: '#444',
  padding: '10px 20px',
  borderRadius: '5px'
};

const buttonStyle = {
  marginTop: '20px',
  padding: '10px 20px',
  backgroundColor: '#333',
  color: '#f0f0f0',
  border: 'none',
  fontSize: '1em',
  cursor: 'pointer',
  transition: 'background-color 0.3s'
};

export default AnimationFinal;
