const sketch2 = (paused) => (p) => {
    p.setup = () => {
      p.createCanvas(p.windowWidth, p.windowHeight);
      p.background(18, 18, 18);
    };
  
    p.draw = () => {
      if (paused) {
        p.noLoop();
      } else {
        p.loop();
      }
  
      p.background(18, 18, 18, 50); // Creates a fading effect
  
      p.noFill();
      p.stroke(240);
      p.strokeWeight(2);
  
      for (let i = 1; i <= 7; i++) {
        let size = i * 30;
        p.rectMode(p.CENTER);
        p.rect(p.width / 2, p.height / 2, size, size);
      }
  
      p.translate(p.width / 2, p.height / 2);
      p.rotate(p.frameCount / 259.0);
  
      for (let i = 1; i <= 21; i++) {
        let size = i * 15;
        p.rect(0, 0, size, size);
      }
    };
  
    p.windowResized = () => {
      p.resizeCanvas(p.windowWidth, p.windowHeight);
    };
  };
  
  export default sketch2;
  