const sketch7 = (paused) => (p) => {
    p.setup = () => {
      p.createCanvas(p.windowWidth, p.windowHeight);
      p.angleMode(p.DEGREES);
      p.background(0);
    };
  
    p.draw = () => {
      if (paused) {
        p.noLoop();
      } else {
        p.loop();
      }
  
      p.translate(p.width / 2, p.height / 2);
      p.rotate(p.frameCount);
      p.stroke(255);
      p.noFill();
      p.strokeWeight(2);
      p.rect(0, 0, 100, 100);
    };
  
    p.windowResized = () => {
      p.resizeCanvas(p.windowWidth, p.windowHeight);
    };
  };
  
  export default sketch7;
  