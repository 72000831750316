const sketch6 = (paused) => (p) => {
  let rects = [];
  let fillIndices = [];

  p.setup = () => {
    p.createCanvas(p.windowWidth, p.windowHeight);
    for (let i = 0; i < 50; i++) {
      rects.push({
        x: p.random(p.width),
        y: p.random(p.height),
        size: p.random(20, 50),
      });
    }
    // Randomly select a few indices to fill with bright orange
    for (let i = 0; i < 10; i++) { // Change the number to control how many squares are filled
      fillIndices.push(Math.floor(p.random(rects.length)));
    }
  };

  p.draw = () => {
    if (paused) {
      p.noLoop();
    } else {
      p.loop();
    }

    p.background(0, 20);
    p.noFill();
    p.stroke(255);
    p.strokeWeight(2);

    for (let i = 0; i < rects.length; i++) {
      if (fillIndices.includes(i)) {
        p.fill(255, 140, 0); // Bright orange color
      } else {
        p.noFill();
      }
      p.rect(rects[i].x, rects[i].y, rects[i].size, rects[i].size);
    }
  };

  p.windowResized = () => {
    p.resizeCanvas(p.windowWidth, p.windowHeight);
  };
};

export default sketch6;
