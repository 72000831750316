const sketch1 = (paused) => (p) => {
    let circles = [];
  
    p.setup = () => {
      p.createCanvas(p.windowWidth, p.windowHeight);
      p.background(18, 18, 18);
    };
  
    p.draw = () => {
      if (paused) {
        p.noLoop();
      } else {
        p.loop();
      }
  
      p.background(18, 18, 18, 20); // Creates a fading effect
  
      if (circles.length < 37) {
        let newCircle = {
          x: p.random(p.width),
          y: p.random(p.height),
          size: 1,
          growthRate: p.random(0.1, 1),
        };
        circles.push(newCircle);
      }
  
      for (let circle of circles) {
        p.noFill();
        p.stroke(240);
        p.strokeWeight(2);
        p.ellipse(circle.x, circle.y, circle.size);
        circle.size += circle.growthRate;
      }
    };
  
    p.windowResized = () => {
      p.resizeCanvas(p.windowWidth, p.windowHeight);
    };
  };
  
  export default sketch1;
  